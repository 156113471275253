





















































































































































































































































































































import { defineComponent, reactive } from '@vue/composition-api'
import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';
import NbTextbox from '@/components/NbTextbox.vue'

export default defineComponent({
	name: 'SignUpPage',

	components: {
		NbTextbox
	},

	setup() {
		const state = reactive({
			accountType: 4,
			agreeToTerms: false,
			currentForm: 'SignUp'
		})

		const signUp = () => {
			Loading.show()

			setTimeout(() => {
				state.currentForm = 'Verification'
				state.agreeToTerms = false

				Loading.hide()

				Notify.create({
					message: '<span style="font-size: 16px; font-family: Quicksand;"><b>Your Neobanx account has been created!</b></span>',
					caption: '<span style="font-size: 15px; font-family: Quicksand;">Please check your email for the confirmation link.</span>',
					color: 'orange-8',
					actions: [{ label: 'DISMISS', color: 'orange-2' }],
					icon: 'check',
					html: true,
					timeout: 10000
				})
			}, 2000)
		}

		return { state, signUp }
	}
})
